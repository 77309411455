import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import { getSafe } from '../utils/getSafe';
// import { BartenderContextProvider } from '../components/Bartender/context/BartenderContext';
import '../styles/faqs.scss';

export default function FaqsTemplate({ data, pageContext }) {
    React.useEffect(() => {
        document.body.classList.add('colored-nav');

        return () => document.body.classList.remove('colored-nav');
    }, []);

    const langKey = pageContext.langcode;
    const newsLetterConfig = getSafe(() => data.nodeNewsletterForm, []);
    
    let allNewsletterFormSignUpCopy = getSafe(() => data.allNewsletterFormSignUpCopy, []);
    if(allNewsletterFormSignUpCopy.edges) {
        allNewsletterFormSignUpCopy = allNewsletterFormSignUpCopy.edges.map(function(elem) {
            return {
                copy:  getSafe(() => elem.node.field_signing_up_copy_2.value, ''),
                copy_2: getSafe(() => elem.node.field_signing_up_copy_block_2.value, ''),
                lang: elem.node.relationships.field_language.field_language_code,
                display_sign_up_checkbox: getSafe(() => elem.node.field_display_sign_up_checkbox, false),
            } 
        });
    }

    const bodyContent = getSafe(() => data.nodeFaqPage.body.value, null);
    const regions = getSafe(() => data.regions.edges, []).reduce((regionsData, data) => {
        let countries = getSafe(() => data.node.relationships.field_region, []);
        return [...regionsData, ...countries];
    }, []);
    const { title, description, keywords } = getSafe(() => data.nodeFaqPage.field_seo_metatag) || {};
    const metaImage = getSafe(
        () =>
            data.nodeFaqPage.relationships.field_faq_seo_meta_image.relationships.field_media_image.localFile.publicURL,
        null
    );
    const fbDomainVerificationCode = getSafe(() => data.nodeSiteSettings.field_domain_verification_code, '');

    return (
        // <BartenderContextProvider>
        <Layout
            langKey={langKey}
            shopMenu={data.nodeShop}
            dataMenu={getSafe(() => data.allMenuLinkContentMenuLinkContent, [])}
            pageContext={pageContext}
            langPageRelation={getSafe(() => data.relatedNodeFaqPage.edges, [])}
            newsLetterConfig={newsLetterConfig}
            allNewsletterFormSignUpCopy={allNewsletterFormSignUpCopy}
            regions={regions}
            siteSettings={getSafe(() => data.nodeSiteSettings, [])}
            disableTheAgeGate={getSafe(() => data.nodeFaqPage.field_disable_the_age_gate, false)}
        >
            <SEO
                title={title}
                lang={langKey}
                description={description}
                keywords={keywords}
                image={metaImage}
                fbDomainVerificationCode={fbDomainVerificationCode}
                langPageRelation={getSafe(() => data.relatedNodeFaqPage.edges, [])}
            />
            <section className="section has-padding-top blv-faqs">
                <div className="container">
                    <h1>{getSafe(() => data.nodeFaqPage.field_title_copy, '')}</h1>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: getSafe(() => bodyContent),
                        }}
                    />
                </div>
            </section>
        </Layout>
        // </BartenderContextProvider>
    );
}

export const pageQuery = graphql`
    query($slug: String!, $langcode: String!, $pagecode: String!) {
        nodeNewsletterForm(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...NewsletterItems
        }
        allNewsletterFormSignUpCopy: allNodeNewsletterForm {
            edges {
                node {
                    field_signing_up_copy_2 {
                        value
                    }
                    field_signing_up_copy_block_2 {
                        value
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                    field_display_sign_up_checkbox
                }
            }
        }
        regions: allNodeRegionsConfigurations(filter: { title: { regex: "/Regions Configurations*/" } }) {
            edges {
                node {
                    relationships {
                        field_region {
                            value: field_code
                            label: field_name
                            relationships {
                                field_local {
                                    name
                                    field_language_code
                                }
                            }
                            field_legal_for_en_int {
                                value
                            }
                            field_legal_for_de_de {
                                value
                            }
                            field_legal_for_fr_fr {
                                value
                            }
                            field_legal_bottom_for_de_de {
                                value
                            }
                            field_legal_bottom_for_en_int {
                                value
                            }
                            field_legal_bottom_for_fr_fr {
                                value
                            }
                            field_display_sign_up_checkbox
                        }
                    }
                }
            }
        }
        nodeSiteSettings(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...SiteSettingsSomeItems
        }
        nodeShop(relationships: { field_language: { field_language_code: { eq: $langcode } } }) {
            ...ShopItems
        }
        nodeFaqPage(
            fields: { slug: { eq: $slug } }
            relationships: { field_language: { field_language_code: { eq: $langcode } } }
        ) {
            ...FaqItems
        }
        allMenuLinkContentMenuLinkContent(
            filter: {
                relationships: { field_language_code: { field_language_code: { eq: $langcode } } }
                enabled: { eq: true }
            }
            sort: { fields: weight, order: ASC }
        ) {
            edges {
                node {
                    weight
                    name: title
                    drupal_id
                    link {
                        uri
                    }
                    drupal_parent_menu_item
                    langcode
                    relationships {
                        field_content {
                            ...LegalMenuItems
                            ...ParagraphMenuItems
                            ...ContactMenuItems
                            ...NutritionMenuItems
                            ...ProductsMenuItems
                        }
                        field_collection_related {
                            ...CollectionMenuItems
                        }
                    }
                    menu_name
                    enabled
                    bundle
                }
            }
        }
        relatedNodeFaqPage: allNodeFaqPage(
            filter: {
                field_page_code: { eq: $pagecode }
                relationships: { field_language: { field_language_code: { ne: $langcode } } }
            }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    path {
                        alias
                    }
                    relationships {
                        field_language {
                            field_language_code
                        }
                    }
                }
            }
        }
    }
`;

FaqsTemplate.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
};
